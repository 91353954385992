import React, { useEffect } from 'react';
import { FiCheck } from "react-icons/fi";
import { useState } from 'react';
// import ScrollAnimation from 'react-animate-on-scroll';
// import {Link} from "react-router-dom";



const billed = [
    {
        id: 1,
        label: "Monthly"
    },
    {
        id: 2,
        label: "Yearly"
    }
]

const PricingOne = () => {
    const [isActive, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());

    }, []);


    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());

    };
    return (
        <>
            <div className="row mb--40 mb_sm--0">
                <div className="col-lg-12">
                    {/* <ul>
                        {billed.map((data) => (
                            <li className="d-flex justify-content-center" key={data.id}>
                                <button onClick={handleChange} className={`btn-default round ${data.label.toLocaleLowerCase() === isActive ? "active" : ""}`}>{data.label}</button>
                            </li>
                        ))}
                    </ul> */}
                    <ul style={{ display: "flex", justifyContent: "center", listStyle: "none" }}>
                        {billed.map((data) => (
                            <li key={data.id} style={{ display: "inline-block", margin: "0 10px" }}>
                                <button onClick={handleChange} className={`btn-default round ${data.label.toLocaleLowerCase() === isActive ? "active" : ""}`}>
                                    {data.label}
                                </button>
                            </li>
                        ))}
                    </ul>

                   

                </div>
            </div>


            <div className="row row--30">
                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Free</h4>
                                <div className="pricing">
                                    <div className="price-wrapper">
                                        <span className="currency">$</span>
                                        <span className="price">0</span>
                                    </div>
                                    <span className="subtitle">USD Per Month</span>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> 1 User Seat</li>
                                    <li><FiCheck /> 5000 Words</li>
                                    <li><FiCheck /> 5 AI Images</li>
                                    <li><FiCheck /> 24/7 Ticket Support</li>
                                    <li><FiCheck /> Unlimited Projects</li>
                                    <li><FiCheck /> Standard Templates</li>
                                    <li><FiCheck /> Unlimited Projects</li>
                                    <li className="text-decoration-line-through"><FiCheck /> Premium Templates Tools</li>
                                    <li className="text-decoration-line-through"><FiCheck /> Custom Templates Request</li>
                                    <li className="text-decoration-line-through"><FiCheck /> Priority Support</li>
                                    <li className="text-decoration-line-through"><FiCheck /> Access to latest Features</li>
                                </ul>
                            </div>
                            <div className="pricing-footer">
                                <a className="btn-default btn-border" href="https://app.vyndai.com/register">Sign Up for Free</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing active">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Standard</h4>
                                <div className="pricing">
                                    <div className="price-wrapper">
                                        <span className="currency">$</span>
                                        <span className="price">{billed[0].label.toLowerCase() === isActive ? 19 : 199}</span>
                                    </div>
                                    <span className="subtitle">USD Per Month</span>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> 1 User Seat</li>
                                    <li><FiCheck /> 100000 Words</li>
                                    <li><FiCheck /> 30 AI Images</li>
                                    <li><FiCheck /> 20 Min STT</li>
                                    <li><FiCheck /> 5000 Char TTS</li>
                                    <li><FiCheck /> 24/7 Ticket Support</li>
                                    <li><FiCheck /> Unlimited Projects</li>
                                    <li><FiCheck /> Unlimited Downloads</li>
                                    <li><FiCheck /> 50+ Templates Tools</li>
                                    <li><FiCheck /> Premium Templates Tools</li>
                                    <li><FiCheck /> Unlimited Projects</li>
                                    <li><FiCheck /> Custom Templates Request</li>
                                    <li><FiCheck /> Priority Support</li>
                                    <li><FiCheck /> Access to latest Features</li>
                                </ul>
                            </div>
                            <div className="pricing-footer">
                                <a className="btn-default" href="https://app.vyndai.com/register">Subscribe Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Advanced</h4>
                                <div className="pricing">
                                    <div className="price-wrapper">
                                        <span className="currency">$</span>
                                        <span className="price">{billed[0].label.toLowerCase() === isActive ? 39 : 449}</span>
                                    </div>
                                    <span className="subtitle">USD Per Month</span>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> 2 User Seat</li>
                                    <li><FiCheck /> Unlimited Words</li>
                                    <li><FiCheck /> 70 AI Images</li>
                                    <li><FiCheck /> 40 Min STT</li>
                                    <li><FiCheck /> 10000 Char TTS</li>
                                    <li><FiCheck /> 24/7 Ticket Support</li>
                                    <li><FiCheck /> Unlimited Projects</li>
                                    <li><FiCheck /> Unlimited Downloads</li>
                                    <li><FiCheck /> Premium Templates Tools</li>
                                    <li><FiCheck /> Unlimited Projects</li>
                                    <li><FiCheck /> Custom Templates Request</li>
                                    <li><FiCheck /> Priority Support</li>
                                    <li><FiCheck /> Access to latest Features</li>
                                </ul>
                            </div>
                            <div className="pricing-footer">
                                <a className="btn-default btn-border" href="https://app.vyndai.com/register">Subscribe Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}
            </div>
        </>
    )
}
export default PricingOne;
