import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactPlayer from 'react-player'

const AdvanceTabOne = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">VyndAI Templates.</h4>
                                        <p className="description">Use VyndAI template you can build a corporate website a short time.</p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Text to Speech.</h4>
                                        <p className="description">Use VyndAI Text to Speech. Easily convert your text into natural-sounding speech.</p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">VyndGPT AIchat.</h4>
                                        <p className="description">Use VyndGPT to instantly communicate with customers and provide support 24/7</p>
                                    </div>
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                    <ReactPlayer 
                                            playing={true}
                                            loop={true}
                                            muted={true}
                                            width='1000px'
                                            height='100%'
                                            url='./images/video/template.webm' />
                                        {/* <div className="thumbnail">
                                            <img src="./images/tab/tabs-02.jpg" alt="Corporate Images" />
                                        </div> */}
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                    <ReactPlayer 
                                            playing={true}
                                            loop={true}
                                            muted={true}
                                            width='1000px'
                                            height='100%'
                                            url='./images/video/tts.webm' />
                                        {/* <div className="thumbnail">
                                            <img src="./images/tab/tabs-03.jpg" alt="Corporate Images" />
                                        </div> */}
                                        
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        {/* <div className="thumbnail">
                                            <img src="./images/tab/tabs-04.jpg" alt="Corporate Images" />
                                        </div> */}
                                        <ReactPlayer 
                                            playing={true}
                                            loop={true}
                                            muted={true}
                                            width='1000px'
                                            height='100%'
                                            url='./images/video/aichat.webm' />
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;