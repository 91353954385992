import React from 'react'
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { FiCalendar, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <>
    <SEO title="VyndAI Blog Details || Blog Details" />
    <Layout>
        <div className="rn-blog-details-area">
            
        <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1 className="theme-gradient">Terms and Conditions</h1>
                                </div>
                                <ul className="rn-meta-list">
                                    <li><FiUser /><Link to='/'>Home</Link></li>
                                    <li><FiCalendar />May 3rd, 2023</li>
                                </ul>
                                {/* <div className="thumbnail alignwide mt--60">
                                    <img className="w-100 radius" src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt="Blog Images" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content">
                            <p className="p-lg">
              These Terms of Service (the{" "}
              <span className="txt-500">&quot;Terms&quot;</span>) are a binding
              contract between you and Vynd AI, Inc. ("Vynd," "we," or "us").
              This Agreement governs your access to and use of the Services.
            </p>
            <p>
              PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND
              EACH PROVISION. THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT
              AND A CLASS ACTION/JURY TRIAL WAIVER THAT REQUIRE, UNLESS YOU OPT
              OUT PURSUANT TO THE INSTRUCTIONS IN SECTION 11(B), THE EXCLUSIVE
              USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO
              RESOLVE DISPUTES BETWEEN YOU AND US, INCLUDING ANY CLAIMS THAT
              AROSE OR WERE ASSERTED BEFORE YOU AGREED TO THESE TERMS. TO THE
              FULLEST EXTENT PERMITTED BY LAW, YOU EXPRESSLY WAIVE YOUR RIGHT TO
              SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR
              CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR
              CLASS MEMBER IN ANY CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL OR
              REPRESENTATIVE ACTION OR PROCEEDING.
            </p>
            <p>
              THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE “I ACCEPT” BUTTON
              BELOW OR BY ACCESSING OR USING THE SERVICES (THE “EFFECTIVE
              DATE”). BY CLICKING ON THE “I ACCEPT” BUTTON BELOW OR BY ACCESSING
              OR USING THE SERVICES YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
              UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE
              THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND,
              IF ENTERING INTO THIS AGREEMENT FOR AN ORGANIZATION, THAT YOU HAVE
              THE LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C) ACCEPT THIS
              AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.
            </p>

            <p>
              IF YOU DO NOT AGREE TO THESE TERMS, PLEASE SELECT THE “I DECLINE”
              BUTTON BELOW OR DO NOT ACCESS OR USE THE SERVICES. IF YOU DO NOT
              ACCEPT THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES
            </p>

            {/* Text  */}
            <p>
              <b>1. Definitions</b>
            </p>
            <p className="p-lg">
              Aggregated Statistics" refers to data and information pertaining
              to the usage of the Services by the Customer, which will be
              utilized by
              <a href="/" className="skyblue-color">
                Vynd AI
              </a>
              in an anonymized and aggregated manner. This includes compiling
              statistical and performance-related information concerning the
              operation and provision of the Services.
            </p>
            <p>
              "Arbitration Agreement" refers to the mandatory individual
              arbitration provision specified in Section 11(b) that applies to
              Vynd AI.
            </p>
            <p>
              "Authorized User" refers to the individuals employed by,
              consultants of, contractors for, or agents of the Customer who are
              authorized by the Customer to access and use the Services under
              the rights granted to the Customer as outlined in this Agreement.
              Additionally, these individuals must have their access to the
              Services purchased in accordance with the terms of this Agreement.
            </p>
            <p>
              "Class Action/Jury Trial Waiver" means the provision in Section
              11(c) that waives the rights of the parties to a class action or
              jury trial.
            </p>
            <p>
              "Confidential Information" pertains to sensitive or proprietary
              information, whether orally or in written, electronic, or other
              form or media, that is related to the business affairs, products,
              confidential intellectual property, trade secrets, third-party
              confidential information, or other proprietary information of
              either party. It does not include information that is already in
              the public domain, already known to the receiving party, lawfully
              obtained by the receiving party on a non-confidential basis from a
              third party, or independently developed by the receiving party.
            </p>
            <p>
              "Customer," "you," or "your" refer to the Customer and the
              Customer's Authorized Users.
            </p>
            <p>
              "Customer Account" means the account that the Customer has on the
              Services.
            </p>
            <p>
              “Customer Content” refers to any content, in any form or medium,
              such as profile information, comments, questions, and other
              content that is submitted, posted, displayed, transmitted, or
              otherwise made available on the Services by or on behalf of the
              Customer or any other Authorized User. It is important to note
              that any output, copies, reproductions, and other derivative works
              that result from the use of the Services as permitted under this
              agreement, and that are derived from Customer Content, are also
              considered as Customer Content. However, Aggregated Statistics are
              excluded from the definition of Customer Content.
            </p>
            <p>
              “Documentation” includes VyndAI's user manuals, handbooks, guides,
              FAQs, and instructional videos, which are related to the Services
              provided by VyndAI to the Customer electronically and are
              available on{" "}
              <a href="http://VyndAI.com" className="skyblue-color">
                VyndAI.com{" "}
              </a>
            </p>
            <p>
              “Feedback” means any communication or material that suggests or
              recommends changes to the Services, including new features or
              functionality related to the Services, and any comments,
              questions, suggestions, ideas, or other similar feedback related
              to the Services that are sent to us by mail, email, telephone, or
              any other means.
            </p>
            <p>
              “Intellectual Property Rights” refers to all existing and future
              patent rights, copyright rights, mask work rights, moral rights,
              rights of publicity, trademark, trade dress and service mark
              rights, goodwill, trade secret rights, and other intellectual
              property rights under the laws of any state, country, territory,
              or other jurisdiction, including all applications, registrations,
              renewals, and extensions thereof."
            </p>
            <p>
              “Vynd AI IP” refers to the (i) Services, including all related
              materials such as software, images, text, graphics, illustrations,
              logos, patents, trademarks, service marks, copyrights,
              photographs, audio, videos, and music, that are transferred or
              contained therein, (ii) the Documentation, (iii) and all
              intellectual property given to the Customer or any other
              Authorized User regarding the Services. It should be noted that
              VyndAI IP includes Aggregated Statistics and any information,
              data, or other content derived from VyndAI's monitoring of the
              Customer's access or use of the Services, but does not include
              Customer Content.
            </p>
            <p>
              The "Privacy Policy" denotes the privacy policy located at
              www.vyndai.com/legal/privacy.
            </p>
            <p>
              The "Services" include the online and/or mobile services, website,
              and software provided by VyndAI under this Agreement as described
              on VyndAI's website at{" "}
              <a href="http://VyndAI.com" className="skyblue-color">
                www.vyndai.com{" "}
              </a>
            </p>
            <p>
              "Third-Party Products" refer to any products, content, services,
              information, websites, or other materials that are owned by third
              parties and are incorporated into or accessible through the
              Services.
            </p>
            <p>
              "User" or "Users" refers to all visitors, users, and others who
              access the Services.
            </p>
            <p>
              "User Accounts" represent different account types for different
              types of Users.
            </p>
            <p>
              <b>2. Access and Use</b>
            </p>
            <p>
              (a) Eligibility. This is an agreement between you and Vynd AI.
              Before using the Services, you must read and agree to this
              Agreement. If you do not agree, you may not use the Services. You
              may use the Services only if you can form a binding contract with
              Vynd AI and comply with this Agreement and all applicable local,
              state, national, and international laws, rules, and regulations.
              Any use or access to the Services by anyone under 13 years old (or
              under 16 years old in Europe) is strictly prohibited and a
              violation of this Agreement. The Services are not available to any
              Users previously removed from the Services by Vynd AI.
            </p>
            <p>
              (b) Provision of Access. Subject to and conditioned on your
              payment of fees and compliance with all the terms and conditions
              of this Agreement, Vynd AI grants you a revocable, non-exclusive,
              non-transferable, non-sublicensable, limited right to access and
              use the Services during the Term solely for your internal business
              operations by Authorized Users in accordance with the terms and
              conditions herein. Vynd AI shall provide you with the necessary
              passwords and access credentials to allow you to access the
              Services.
            </p>
            <p>
              (c) Documentation License. Subject to the terms and conditions
              contained in this Agreement, Vynd AI grants you a non-exclusive,
              non-sublicensable, non-transferable license for Authorized Users
              to use the Documentation during the Term solely for your internal
              business purposes in connection with the use of the Services
            </p>
            <p>
              (d) Accounts. Your Customer Account gives you access to the
              services and functionality that we may establish and maintain from
              time to time and in our sole discretion. We may maintain other
              User Accounts. If you open a Customer Account on behalf of a
              company, organization, or other entity, then “you” includes you
              and that entity. By connecting to Vynd AI with a third-party
              service, you give us permission to access and use your information
              from that service as permitted by that service, and to store your
              log-in credentials for that service.
            </p>
            <p>
              You may never use other Users’ User Accounts without permission.
              When creating your Customer Account, you must provide accurate and
              complete information, and you must keep this information up to
              date. You are solely responsible for the activity that occurs on
              your Customer Account, and you must keep your Customer Account
              password secure. We encourage you to use “strong” passwords
              (passwords that use a combination of upper and lower case letters,
              numbers, and symbols) with your Customer Account. You must notify
              Vynd AI immediately of any breach of security or unauthorized use
              of your Customer Account. Vynd AI will not be liable for any
              losses caused by any unauthorized use of your Customer Account.
            </p>
            <p>
              You may control your Customer Account profile and how you interact
              with the Services by changing the settings in your settings page.
              By providing Vynd AI your email address, you consent to our using
              the email address to send you Services-related notices, including
              any notices required by law, in lieu of communication by postal
              mail. We may also use your email address to send you other
              messages, such as changes to features of the Services and special
              offers. If you do not want to receive such email messages, you may
              opt-out or change your preferences in your settings page. Opting
              out may prevent you from receiving email messages regarding
              updates, improvements, or offers.
            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</>
  )
}

export default Terms