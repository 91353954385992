import React from 'react'
import { FiCheck, FiStar } from "react-icons/fi";

const AdvancePricingOne = () => {
    return (
        <div className="advance-pricing">
            <div className="inner">
                <div className="row row--0">
                    <div className="col-lg-6">
                        <div className="pricing-left">
                            <h3 className="main-title">Premium Plan.</h3>
                            <p className="description">Signup today for free. No credit card required.</p>
                            <div className="price-wrapper">
                                <span className="price-amount">$39 <sup>/mo</sup></span>
                            </div>
                            <div className="pricing-btn-group">
                                <button className="btn-default">Subscribe Now</button>
                                <button className="btn-default btn-border">Start for Free</button>
                            </div>
                            <div className="rating">
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                            </div>
                            <span className="subtitle">rated 4.5/5 Stars in 1000+ reviews.</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="pricing-right">
                            <div className="pricing-offer">
                                <div className="single-list">
                                    <h4 className="price-title">Basic Plans You can Get.</h4>
                                    <ul className="plan-offer-list">
                                    <li><FiCheck /> 100000 Words</li>
                                <li><FiCheck /> 5 AI Images</li>
                                <li><FiCheck /> 24/7 Ticket Support</li>
                                <li><FiCheck /> Unlimited Projects</li>
                                <li><FiCheck /> Unlimited Downloads</li>
                                    </ul>
                                </div>
                                <div className="single-list mt--40">
                                    <h4 className="price-title">Advance Plans You can Get.</h4>
                                    <ul className="plan-offer-list">
                                        <li><FiCheck /> Everything in Basic</li>
                                        <li><FiCheck /> Unlimited Projects</li>
                                <li><FiCheck /> Unlimited Downloads</li>
                                <li><FiCheck /> Premium Templates Tools</li>
                                <li><FiCheck /> Unlimited Projects</li>
                                <li><FiCheck /> Custom Templates Request</li>
                                <li><FiCheck /> Priority Support</li>
                                <li><FiCheck /> Access to latest Features</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancePricingOne
