import React , {useState} from 'react';
//import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function ContactForm({props , formStyle}) {
    
        const [formData, setFormData] = useState({
          subject: '',
          name: '',
          email: '',
          phone: '',
          message: '',
        });

        const handleChange = (event) => {
            const { name, value } = event.target;
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
          };

    const [ result,showresult ] = useState(false);

    
        const handleSubmit = (event) => {
            event.preventDefault();
        
            const { subject, name, email, phone, message } = formData;
        
            // send email using SMTPJS
            window.Email.send({
              SecureToken: 'b0f8f98c-6285-44d9-ab2a-250607df4057',
              To: 'info@vyndai.com',
              From: 'contactform@vyndai.com',
              Subject: subject,
              Body: `
                <p><b>Name:</b> ${name}</p>
                <p><b>Email:</b> ${email}</p>
                <p><b>Phone:</b> ${phone}</p>
                <p><b>Message:</b> ${message}</p>
              `,
            }).then(showresult(true));
            // }).then((message) => alert(message));
            
            // reset form data
            setFormData({
              subject: '',
              name: '',
              email: '',
              phone: '',
              message: '',
            });

            setTimeout(() => {
                showresult(false);
            }, 5000);
          };

    // const sendEmail = (e) => {
    //     e.preventDefault();
    //     emailjs
    //     .sendForm(
    //         'service_p4x3hv8', 
    //         'template_jgfr42f', 
    //         e.target, 
    //         'user_jrfTH2e0Ely35ZCVFdT9S'
    //     )
    //     .then((result) => {
    //         console.log(result.text);
    //         }, 
    //         (error) => {
    //             console.log(error.text);
    //         }
    //     );
    //     e.target.reset();
    //     showresult(true);
    // };

    // setTimeout(() => {
    //     showresult(false);
    // }, 5000);

    return (
        // <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
        <form className={`${formStyle}`} action="" onSubmit={handleSubmit}>
            <div className="form-group">
                <input 
                type="text"
                name="name"
                value={formData.name}
                  onChange={handleChange}
                placeholder="Your Name"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                value={formData.email}
                  onChange={handleChange}
                placeholder="Email Address"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                value={formData.phone}
                  onChange={handleChange}
                placeholder="Phone Number"
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                value={formData.subject}
                  onChange={handleChange}
                placeholder="Subject"
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                value={formData.message}
                  onChange={handleChange}
                placeholder="Your Message"
                required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Submit Now</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
