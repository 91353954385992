/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Typed from 'react-typed';

const AboutOneCopy = ({ title, p1, p2, p3, p4, imageUrl }) => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">{title}<br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Text to Speech.",
                                            "AI Code Generator.",
                                            "AI Chat.",
                                            "Speech to Text",
                                            "Academic Essay",
                                            "Code Emails"
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>{p1}</p>
                                <p>{p2}</p>
                                <p>{p3}</p>
                                <p>{p4}</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="https://app.vyndai.com/"><span>Get Started - Free</span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src={imageUrl} alt="About Images" />
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOneCopy
