import React from 'react'
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { FiCalendar, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <>
    <SEO title="VyndAI Blog Details || Blog Details" />
    <Layout>
    <div className="rn-blog-details-area">
            
            <div className="post-page-banner rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="content text-center">
                                    <div className="page-title">
                                        <h1 className="theme-gradient">Privacy Policy</h1>
                                    </div>
                                    <ul className="rn-meta-list">
                                        <li><FiUser /><Link to='/'>Home</Link></li>
                                        <li><FiCalendar />May 3rd, 2023</li>
                                    </ul>
                                    {/* <div className="thumbnail alignwide mt--60">
                                        <img className="w-100 radius" src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt="Blog Images" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-details-content pt--60 rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="content">
                                <p className="p-lg">
                  These Terms of Service (the{" "}
                  <span className="txt-500">&quot;Terms&quot;</span>) are a binding
                  contract between you and Vynd AI, Inc. ("Vynd," "we," or "us").
                  This Agreement governs your access to and use of the Services.
                </p>
                <p>
                  PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND
                  EACH PROVISION. THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT
                  AND A CLASS ACTION/JURY TRIAL WAIVER THAT REQUIRE, UNLESS YOU OPT
                  OUT PURSUANT TO THE INSTRUCTIONS IN SECTION 11(B), THE EXCLUSIVE
                  USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO
                  RESOLVE DISPUTES BETWEEN YOU AND US, INCLUDING ANY CLAIMS THAT
                  AROSE OR WERE ASSERTED BEFORE YOU AGREED TO THESE TERMS. TO THE
                  FULLEST EXTENT PERMITTED BY LAW, YOU EXPRESSLY WAIVE YOUR RIGHT TO
                  SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR
                  CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR
                  CLASS MEMBER IN ANY CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL OR
                  REPRESENTATIVE ACTION OR PROCEEDING.
                </p>
                <p>
                  THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE “I ACCEPT” BUTTON
                  BELOW OR BY ACCESSING OR USING THE SERVICES (THE “EFFECTIVE
                  DATE”). BY CLICKING ON THE “I ACCEPT” BUTTON BELOW OR BY ACCESSING
                  OR USING THE SERVICES YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
                  UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE
                  THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND,
                  IF ENTERING INTO THIS AGREEMENT FOR AN ORGANIZATION, THAT YOU HAVE
                  THE LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C) ACCEPT THIS
                  AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.
                </p>
    
                <p>
                  IF YOU DO NOT AGREE TO THESE TERMS, PLEASE SELECT THE “I DECLINE”
                  BUTTON BELOW OR DO NOT ACCESS OR USE THE SERVICES. IF YOU DO NOT
                  ACCEPT THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES
                </p>
    
                {/* Text  */}
                <p>
                  <b>1. Definitions</b>
                </p>
                <p className="p-lg">
                  Aggregated Statistics" refers to data and information pertaining
                  to the usage of the Services by the Customer, which will be
                  utilized by
                  <a href="/" className="skyblue-color">
                    Vynd AI
                  </a>
                  in an anonymized and aggregated manner. This includes compiling
                  statistical and performance-related information concerning the
                  operation and provision of the Services.
                </p>
                <p>
                  "Arbitration Agreement" refers to the mandatory individual
                  arbitration provision specified in Section 11(b) that applies to
                  Vynd AI.
                </p>
                <p>
                  "Authorized User" refers to the individuals employed by,
                  consultants of, contractors for, or agents of the Customer who are
                  authorized by the Customer to access and use the Services under
                  the rights granted to the Customer as outlined in this Agreement.
                  Additionally, these individuals must have their access to the
                  Services purchased in accordance with the terms of this Agreement.
                </p>
                <p>
                  "Class Action/Jury Trial Waiver" means the provision in Section
                  11(c) that waives the rights of the parties to a class action or
                  jury trial.
                </p>
                <p>
                  "Confidential Information" pertains to sensitive or proprietary
                  information, whether orally or in written, electronic, or other
                  form or media, that is related to the business affairs, products,
                  confidential intellectual property, trade secrets, third-party
                  confidential information, or other proprietary information of
                  either party. It does not include information that is already in
                  the public domain, already known to the receiving party, lawfully
                  obtained by the receiving party on a non-confidential basis from a
                  third party, or independently developed by the receiving party.
                </p>
                <p>
                  "Customer," "you," or "your" refer to the Customer and the
                  Customer's Authorized Users.
                </p>
                <p>
                  "Customer Account" means the account that the Customer has on the
                  Services.
                </p>
                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Layout>
</>
  )
}

export default Privacy