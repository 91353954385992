/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiPlay } from "react-icons/fi";
// import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
//import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
// import TeamTwo from '../elements/team/TeamTwo';
//import TestimonialThree from "../elements/testimonial/TestimonialThree";
import TestimonialOne from "..//elements/testimonial/TestimonialOne"
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
// import Mission from "../elements/about/Mission";
// import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import HeaderTwoCopy from '../common/header/HeaderTwoCopy';
//import CounterUpFour from '../elements/counterup/CounterUpFour';
import ServiceOne from '../elements/service/ServiceOne';
// import AboutTwo from '../elements/about/AboutTwo';
// import AbouThree from '../elements/about/AboutThree';
// import AboutFour from '../elements/about/AboutFour';
// import AboutFive from '../elements/about/AboutFive';
// import AboutSix from '../elements/about/AboutSix';
import AboutOneCopy from '../elements/about/AboutOneCopy';
// import Tab from '../elements/tab/Tab';
import TabOne from '../elements/tab/TabOne';
import FsLightbox from 'fslightbox-react';
//var BlogListData = BlogClassicData.slice(0, 3);

const PopupContent =  {
    image: "./images/bg/front.png",
    popupLink: [ "https://youtu.be/MteGLshG2HM"],
}
const HomePage = () => {
    const [toggler, setToggler] = useState(false);
  return (
    <>
<SEO title="Business Consulting 02" />
            <main className="page-wrapper">
                <HeaderTopNews />
                {/* <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" /> */}
                <HeaderTwoCopy btnStyle="round" HeaderSTyle="header-not-transparent"/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">VYNDAI CONTENT OPTIMIZER</span>
                                    <h1 className="title theme-gradient display-two">Revolutionize your <br /> {" "}
                                        <Typed
                                            strings={[
                                                "Marketing Ads.",
                                                "Blog Post.",
                                                "Image Art.",
                                                "Video Scripts.",
                                                "Text to Speech.",
                                                "Code Gen.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Welcome to Vyndai, the ultimate content creation tool. With Vyndai, you can create amazing content faster than ever before.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://app.vyndai.com/">Try VyndAI Free <i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Request Demo <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />



                 {/* Start Counterup Area  */}
                 <div className="our-counterup-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="video-popup icon-center">
                    <div className="thumbnail">
                        <img className="radius-small" src={`${PopupContent.image}`} alt="Corporate Image" />
                    </div>
                    <div className="video-icon">
                        <button className="btn-default rounded-player" onClick={ () => setToggler(!toggler) }><span><FiPlay /></span></button>
                    </div>
                    <FsLightbox toggler={ toggler } sources={PopupContent.popupLink} />
                </div>
                                
                                {/* <div className="thumbnail">
                                    <img className="radius w-100" src="./images/bg/front.png" alt="Images" />
                                </div> */}
                                {/* Start Elements Area  */}
                                {/* <div className="rwt-counterup-area ptb--60">
                                    <div className="col-lg-12">
                                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                                    </div>
                                </div> */}
                                {/* End Elements Area  */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Counterup Area  */}

                 {/* Start Service Area  */}
                 <div className="rn-service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "We're Better. Here's Why…"
                                    description = "VyndAI is the ultimate AI-powered content generator built for:"
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                    
                </div>
                {/* End Service Area  */}

                 

                

                {/* Start About Area  */}
                <AboutOne 
                title="Never run out of" 
                p1="AI-powered idea generation - Our advanced algorithms analyze popular topics and keywords to suggest blog post ideas that are tailored to your industry and audience."
                p2="Versatile ideas - VyndAI generates blog post ideas for a variety of industries and niches, including technology, health, finance, and more." 
                p3="Customizable - You can specify the length, tone, and style of the blog post ideas to fit your brand and audience." 
                p4="Time-saving - With VyndAI, you can generate blog post ideas in seconds, freeing up your time to focus on writing and other important tasks."
                imageUrl="./images/about/abt01.png" />
                
                <AboutOneCopy 
                title="More Features" 
                p1="SEOs: Use the most advanced AI technology to create content that easily outperforms the competition and drives better results."
                p2="Content Marketers: Increase your content's ROI by creating high-quality content that reliably attracts and converts organic traffic." 
                p3="Marketing Agencies: Streamline your content production process and spend less time researching and creating high-quality SEO content for your clients." 
                p4="Competitive edge - Stay ahead of your competitors by creating unique and engaging blog content that attracts and retains your target audience."
                imageUrl="./images/about/abt02.png"
                />

                <TabOne
                b1="AI Chat"
                b2="Text to Speech"
                b3="AI Code"
                pa01="VyndAI's AI chat feature allows businesses to provide instant support and engage with customers 24/7, even outside of business hours."
                pa02="With its advanced algorithms and natural language processing, the chatbot can answer frequently asked questions and handle basic queries, freeing up staff to focus on more complex tasks."
                pb01="VyndAI's text to speech feature can convert written content into lifelike speech, making it accessible to people with visual impairments and other disabilities."
                pb02="This feature can also be useful for creating audio versions of articles, presentations, and other content, as well as for adding voiceovers to videos."
                pc01="The AI code feature automates coding tasks, saving developers time and reducing the potential for human error. It can analyze code and suggest improvements, as well as generate code from natural language instructions."
                pc02="This can help businesses and developers accelerate their development process and improve the quality of their code."
                
                />


                {/* <AboutTwo /> 
                <AbouThree />   */}
                
                
                
                {/* End About Area  */}

                <Separator />                            
                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        />
                    </div>
                </div> */}
                {/* End Service Area  */}


                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area pt-5">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Call To Action Area  */}

                <Separator />                               
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Client."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        {/* <TestimonialOne teamStyle="" /> */}
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Testimonial Area  */}


                 {/* Start Team Area  */}
                 {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Experts."
                                        title = "Our Company Experts."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                    </div>
                </div> */}
                {/* End Team Area  */}
                <Separator /> 
                {/* Start Mission Area   */}
                {/* <Mission />                     */ }
                {/* Start Mission Area  */}


               

                <Separator />   
                {/* <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <FooterTwo />
                <Copyright />
            </main>    </>
  )
}

export default HomePage